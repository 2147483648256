.pendingExplain {
    background-color: #ebebeb;
    border-radius: 20px;
    padding: 10px 40px;
    margin-top: 40px;
    margin-bottom: 40px;
    padding: 30px 0px;
}

.spinnerWrapper {
    margin-top: 40px;
    width: 170px;
    margin-left: auto;
    margin-right: auto;
}

.pendingTitle {
    text-align: center;
    color: rgb(0, 74, 77);
}

.libnameSpan {
    color: var(--main-color);
}