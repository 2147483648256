@import url('https://fonts.googleapis.com/css?family=Roboto');

.appHeader {
    height: 110px;
    background-color: var(--main-color);
    padding-left: 10px;
}

.titleSubtitleContainer {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    
    padding-top: 30px;
}

.singleTitleSubtitleContainer {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    
    padding-top: 30px;
}

.appTitle {
    color: white;
    font-size: 2.5rem;
    text-decoration: none;
    font-weight: bold;
    margin-left: 10px;
}

.appTitle:active {
    color: rgb(241, 241, 241);
}

.subtitleWrapper {
    margin-top: 7px;
}

.appSubtitle {
    font-weight: bold;
    font-size: 2rem;
    background: white;
    color: var(--main-color);
    padding: 2px 4px;
    margin-left: 7px;
}

@media (max-width:600px) {
    .titleSubtitleContainer {
        flex-direction: column;
    }

    .titleSubtitleContainer {
        margin-top: 0px;
        padding-top: 7px;
    }

    .subtitleWrapper {
        margin-top: 3px;
    }
}