.homeWrapper {
    display: flex;
}

.leftContent {
    width: 70%;
}

.rightContent {
    width: 30%;
    padding-left: 50px;
}

.interactiveWrapper {
    border-radius: 5px;
    background-color: #f0f0f0;
    padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.badgePrefix {
    font-size: 18px;
}

.badgeInput {
    font-size: 18px;
    border: none;
    padding: 1px;
    background: none;
    border-radius: 5px;
    color: var(--main-color);
}

.badgePostfix {
    font-size: 18px;
    margin-right: 10px;
}

.badgeImg {
    margin-bottom: -5px;
    margin-top: 5px;
}

.githubLogoWrapper {
    width: 100%;
    margin-bottom: 20px;
}

.starBtnWrapper {
    width: 100%;
    text-align: center;
    margin-top: 20px;
}

.githubLink {
}

.githubLogo {
    display: block;
    width: 120px;
    height: auto;

    margin-left: auto;
    margin-right: auto;

    margin-top: 10px;
}

@media (max-width:700px) {
    .homeWrapper {
        flex-direction: column-reverse;
    }

    .leftContent {
        width: 100%;
    }
    
    .rightContent {
        width: 100%;
        padding-left: 0px;
    }
}

.badgeWrapper {
}

.markdownCode {
    text-align: justify;
    font-size: 18px;
    color: rgb(0, 87, 90);
    background-color: #f0f0f0;
    padding: 10px;
}

.homeSpan {
    font-weight: bold;
}