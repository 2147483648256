@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --main-color:#00979D;
}

* {
  font-family: 'Roboto', sans-serif;
}

body {
  background-color: #fcffff;
}

.main {
  padding: 0px 10%;
  margin-top: 40px;
}

h4 {
  color: #00979D;
  color: var(--main-color);
}

h2 {
  color: #00979D;
  color: var(--main-color);
}

h3 {
  color: #00979D;
  color: var(--main-color);
}

p {
  text-align: justify;
  color: rgb(79, 95, 116);
  font-size: 1.15rem;
  line-height: 2rem;
}

.aLink {
  color: #00979D;
  color: var(--main-color);
  text-decoration: none;
  font-weight: bold;
}

.aLink:hover {
  text-decoration: underline;
}

@media only screen and (min-width: 1500px) {
  .main {
      padding: 0px 25%;
  }
}

@media (max-width:700px) {
  p {
    font-size: 1.1rem;
    line-height: 1.5rem;
  }

  .main {
    margin-top: 5px;
  }
}
.appHeader {
    height: 110px;
    background-color: var(--main-color);
    padding-left: 10px;
}

.titleSubtitleContainer {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    
    padding-top: 30px;
}

.singleTitleSubtitleContainer {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    
    padding-top: 30px;
}

.appTitle {
    color: white;
    font-size: 2.5rem;
    text-decoration: none;
    font-weight: bold;
    margin-left: 10px;
}

.appTitle:active {
    color: rgb(241, 241, 241);
}

.subtitleWrapper {
    margin-top: 7px;
}

.appSubtitle {
    font-weight: bold;
    font-size: 2rem;
    background: white;
    color: var(--main-color);
    padding: 2px 4px;
    margin-left: 7px;
}

@media (max-width:600px) {
    .titleSubtitleContainer {
        flex-direction: column;
    }

    .titleSubtitleContainer {
        margin-top: 0px;
        padding-top: 7px;
    }

    .subtitleWrapper {
        margin-top: 3px;
    }
}
.appFooter {
    margin-top: 50px;
    height: 100px;
    background-color: var(--main-color);
}
  
.footerText {
    padding-top: 25px;
    text-align: center;
}

.contactLink {
    color: white;
    text-decoration: none;
    font-size: 1rem;
}

.contactLink:hover {
    font-weight: bold;
}

.homeLink {
    color: white;
    text-decoration: none;
    font-size: 1.3rem;
}

.homeLink:hover {
    color: white;
    text-decoration: underline;
    font-size: 1.3rem;
}
.what-are-libs {
    background-color: #ebebeb;
    border-radius: 20px;
    padding: 10px 40px;
    margin: 0px -30px;
    margin-top: 40px;
    margin-bottom: 40px;
}

.mini {
    font-size: 1rem;
}

.explanationImg {
    margin-top: 50px;
    margin-bottom: 50px;
    max-width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.optionsContainer {
    display: flex;
}

.option {
    width: 50%;
    text-align: center;
    padding: 10px 20px;
    background-color: var(--main-color);
    border-color: var(--main-color);
    border-style: solid;
    border-width: 2px;
    color:  rgb(243, 255, 255);
    text-decoration: none;
}

.option:nth-child(1){
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}

.option:nth-child(2){
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

.active {
    background-color: white;
    color: var(--main-color);
    font-weight: bold;
}

.libname {
    background: #ebebeb;
    padding: 2px 1px;
}

.phrase {
    font-weight: bold;
    -webkit-text-decoration: transparent;
            text-decoration: transparent;
}


@media (max-width:600px) {
    .what-are-libs {
        border-radius: 15px;
        padding: 1px 30px;
        margin: 0px -20px;
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .option {
        font-size: 14px;
    }
}
.pendingExplain {
    background-color: #ebebeb;
    border-radius: 20px;
    padding: 10px 40px;
    margin-top: 40px;
    margin-bottom: 40px;
    padding: 30px 0px;
}

.spinnerWrapper {
    margin-top: 40px;
    width: 170px;
    margin-left: auto;
    margin-right: auto;
}

.pendingTitle {
    text-align: center;
    color: rgb(0, 74, 77);
}

.libnameSpan {
    color: var(--main-color);
}

.homeWrapper {
    display: flex;
}

.leftContent {
    width: 70%;
}

.rightContent {
    width: 30%;
    padding-left: 50px;
}

.interactiveWrapper {
    border-radius: 5px;
    background-color: #f0f0f0;
    padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.badgePrefix {
    font-size: 18px;
}

.badgeInput {
    font-size: 18px;
    border: none;
    padding: 1px;
    background: none;
    border-radius: 5px;
    color: var(--main-color);
}

.badgePostfix {
    font-size: 18px;
    margin-right: 10px;
}

.badgeImg {
    margin-bottom: -5px;
    margin-top: 5px;
}

.githubLogoWrapper {
    width: 100%;
    margin-bottom: 20px;
}

.starBtnWrapper {
    width: 100%;
    text-align: center;
    margin-top: 20px;
}

.githubLink {
}

.githubLogo {
    display: block;
    width: 120px;
    height: auto;

    margin-left: auto;
    margin-right: auto;

    margin-top: 10px;
}

@media (max-width:700px) {
    .homeWrapper {
        flex-direction: column-reverse;
    }

    .leftContent {
        width: 100%;
    }
    
    .rightContent {
        width: 100%;
        padding-left: 0px;
    }
}

.badgeWrapper {
}

.markdownCode {
    text-align: justify;
    font-size: 18px;
    color: rgb(0, 87, 90);
    background-color: #f0f0f0;
    padding: 10px;
}

.homeSpan {
    font-weight: bold;
}
.libItemWrapper {
    border-radius: 2px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(52, 84, 110);
    padding: 2px 2px;
    margin: 5px 0px;

    display: flex;
    width: 100%;
}

.linkLibWrapper {
    text-decoration: none;
}

.leftColoredTag {
    width: 10px;
    margin: -2px;
    margin-right: 4px;
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;

    background-color: var(--main-color);
}

.longer {
    width: 17px;
}

.spanLibName {
    color: black;
    word-wrap:break-word;
    table-layout: fixed;
}
