@import url('https://fonts.googleapis.com/css?family=Roboto');

.appFooter {
    margin-top: 50px;
    height: 100px;
    background-color: var(--main-color);
}
  
.footerText {
    padding-top: 25px;
    text-align: center;
}

.contactLink {
    color: white;
    text-decoration: none;
    font-size: 1rem;
}

.contactLink:hover {
    font-weight: bold;
}

.homeLink {
    color: white;
    text-decoration: none;
    font-size: 1.3rem;
}

.homeLink:hover {
    color: white;
    text-decoration: underline;
    font-size: 1.3rem;
}